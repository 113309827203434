<template>
  <div class="workflow-success d-flex flex-column justify-content-center align-items-center w-100">
    <h4 class="text-center">Thank You! We have received your completed document.</h4>
    <h6 class="text-center">{{reviewDescription()}}</h6>
    <h6 class="text-center">If this window does not close in 10 seconds, please click the button below.</h6>
    <h6 class="text-center"><b-link @click="close()">Close Window</b-link></h6>
  </div>
</template>
<script>
import get from 'just-safe-get';

export default {
  name: 'workflowSuccess',
  data: function () {
    return {}
  },

  methods: {
    close: function() {
      window.close()
    },

    reviewDescription: function() {
      const adminFirstName = get(this.$root, 'leaveAdmin.firstName')
      const adminLastName = get(this.$root, 'leaveAdmin.lastName')

      if (adminFirstName!==undefined && adminLastName!==undefined) {
        return `${adminFirstName} ${adminLastName}, your Program Administrator, will review for approval.`
      }

      return 'Your Program Administrator will review for approval.'
    }
  },

  created: function () {
    setTimeout(function() {
      window.close()
    }, 10000);
  }
}
</script>

<style lang="scss">
@import "../scss/app";

.workflow-success {
  a {
    color: $bodyLinkColor;
  }

  h6, .h6 {
    font-weight: 500 !important;
    font-size: 1.11rem !important;
  }
}
</style>
